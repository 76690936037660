<template>
	<div>
		<!--委员反馈-->
		<el-dialog title="委员反馈" :visible.sync="showfk">
			<div style="height:600px;overflow-x: hidden;overflow-y: auto;" ref="print">
				<div class="content">
					<div class="title">
						政协宁夏委员会提交办理情况征询表
					</div>
					<div class="table">
						<div class="line">
							<div class="name">
								姓名
							</div>
							<div class="value">
								{{detail.user_name}}
							</div>
							<div class="name" style="width: 25%;">
								通讯地址或工作单位
							</div>
							<div class="value" style="width: 40%;">
								{{detail.address?detail.address:'暂无'}}
							</div>
						</div>
						<div class="line">
							<div style="height: 80px;width: 35%;display: flex;">
								<div class="name" style="height: 100%;border-top: none;width: 43%;">
									提案<br>编号
								</div>
								<div class="value" style="height: 100%;border-top: none;width: 57%;">
									{{detail.code}}
								</div>
							</div>
							<div style="height: 80px;width: 65%;">
								<div style="display: flex;align-items: center;">
									<div class="name" style="width: 38.5%;border-top: none;">
										提案标题
									</div>
									<div class="value" style="border-top: none;border-right: 1px solid #333;width: 61.5%;">
										{{detail.name}}
									</div>
								</div>
								<div style="display: flex;align-items: center;">
									<div class="name" style="width: 38.5%;border-top: none;">
										承办单位
									</div>
									<div class="value" style="border-top: none;border-right: 1px solid #333;width: 61.5%;">
										{{detail.organizational_name}}
									</div>
								</div>
							</div>
						</div>
						<div class="line">
							<div style="display: flex;width: 60%;">
								<div class="name" style="border-top: none;width: 45%;">
									收到答复日期
								</div>
								<div class="value" style="border-top: none;width: 55%;">
									{{detail.dftime?detail.dftime.slice(0, -5):''}}
								</div>
							</div>
							<div style="display: flex;width: 40%;">
								<div class="name" style="border-top: none;width: 45%;">
									反馈日期
								</div>
								<div class="value" style="border-top: none;width: 55%;border-right: 1px solid #333;">
									{{detail.pjtime?detail.pjtime.slice(0, -5):''}}
								</div>
							</div>
						</div>
						<div class="line">
							<div style="display: flex;width: 10%;height: 230px;">
								<div class="name" style="border-top: none;width: 100%;height: 250px;border-right: 1px solid #333;">
									委员对办理的具体意见和建议
								</div>
							</div>
							<div style="width: 90%;height: 230px;">
								<div style="display: flex;width: 100%;">
									<div class="name" style="width: 15%;border-top: none;border-left: none;">
										满意
									</div>
									<div class="value" style="width: 15%;border-top: none;border-right: none;">
										<div v-if="detail.sfmy==3" style="width: 10px;height:20px;border-right: 2px solid #333;border-bottom: 2px solid #333;transform: rotate(45deg);">
											
										</div>
									</div>
									<div class="name" style="width: 25.5%;border-top: none;border-right: none;">
										基本满意
									</div>
									<div class="value" style="width: 15%;border-top: none;border-right: none;">
										<div v-if="detail.sfmy==2" style="width: 10px;height: 20px;border-right: 2px solid #333;border-bottom: 2px solid #333;transform: rotate(45deg);">
											
										</div>
									</div>
									<div class="name" style="width: 15.1%;border-top: none;border-right: none;">
										不满意
									</div>
									<div class="value" style="width: 14.4%;border-top: none;border-right: 1px solid #333;">
										<div v-if="detail.sfmy==1" style="width: 10px;height: 20px;border-right: 2px solid #333;border-bottom: 2px solid #333;transform: rotate(45deg);">
											
										</div>
									</div>
								</div>
								<div class="value" style="width:100%;border-top: none;border-left:none;height: 210px;border-right: 1px solid #333;flex-direction: column;">
									<div>
										{{detail.qhzxyjb}}
									</div>
									<div style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 50px;">
										<img :src="detail.qm" alt="" style="width: 100px;height: 50px;"/>
									</div>
									
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		
			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 10px;">
				<el-button type="success" size="small" @click.stop="print" style="margin-left: 10px;">打印</el-button>
				</el-button>
				<el-button type="success" @click="showfk=false" size="small">确定</el-button>
			</span>
		</el-dialog>
	</div>
	
</template>

<script>
	export default {
		data() {
			return{
				detail:'',
				showfk:false
			}
		},
		methods:{
			print(){
				this.$printPage(this.$refs.print)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.table{
		width: 100%;
		margin-top: 20px;
		.line{
			display: flex;
			align-items: center;
			.name{
				width: 15%;
				border: 1px solid #333333;
				font-size: 16px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 3px;
				border-right: none;
			}
			.value{
				width: 20%;
				border: 1px solid #333333;
				font-size: 16px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 3px;
			}
			.value:nth-child(2){
				border-right: none;
			}
		}
	}
	.cover {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background-color: #fff;
		z-index: 9999;
		padding: 20px;
	}
	.content{
		margin-top: 20px;
		.title{
			color: #333333;
			font-size: 23px;
			font-weight: bold;
			text-align: center;
		}
	}
</style>